import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ContactListItem = ({ content }) => {
  let phone = content.phone ? content.phone.trim() : null

  phone =
    phone && parseInt(phone.length) === 10 ? "+33" + phone.substring(1) : phone

  return (
    <div className="contact-list-item card h-full">
      <div className="card-content h-full flex flex-col p-4 bg-primary-light text-white">
        <div className="media flex-1 flex flex-col sm:flex-row mb-4 -mx-2">
          {content.relationships.image &&
            content.relationships.image.localFile.childImageSharp && (
              <div className="media-right flex-initial px-2 mb-4 sm:mb-0">
                <Img
                  fluid={
                    content.relationships.image.localFile.childImageSharp.fluid
                  }
                  alt={content.title}
                  className="w-32 h-32"
                />
              </div>
            )}
          <div className="media-content flex-1 px-2">
            <div className="h4 mb-2">{content.title}</div>

            {content.address && (
              <div className="contact-address">
                <span
                  className="content"
                  dangerouslySetInnerHTML={{ __html: content.address.value }}
                />
              </div>
            )}

            {content.contactName && (
              <p className="contact-name mb-0">
                <i className="fas fa-user mr-2"></i>
                {content.contactName}
              </p>
            )}
          </div>
        </div>
        <div className="buttons flex flex-col sm:flex-row -mx-2">
          {phone && (
            <div className="w-full sm:w-1/2 px-2 mb-4 sm:mb-0">
              <a
                href={`tel:${phone}`}
                className="block w-full button button-tertiary text-center"
              >
                <i className="fas fa-phone mr-2">
                  <span className="hidden">Appeler</span>
                </i>
                Appeler
              </a>
            </div>
          )}
          {content.email && (
            <div className="w-full sm:w-1/2 px-2">
              <a
                href={`mailto:${content.email}`}
                className="block w-full button button-tertiary text-center"
              >
                <i className="fas fa-envelope mr-2">
                  <span className="hidden">Email</span>
                </i>
                Email
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ContactListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default ContactListItem
